/* html {
  overflow-y: scroll;
} */

.App {
  /* text-align: center; */
  background: white;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-size: calc(6px + 1vmin);
}
.NavDiv {
  background-color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 10px;
}

.TitleText {
  font-family: "Titillium Web", sans-serif;
  font-size: calc(20px + 2vmin);
  line-height: 1.125;
}

.TitleTextOpenSans {
  font-family: "Open Sans", sans-serif;
  font-size: calc(16px + 2vmin);
  line-height: 1.125;
}

.LargeText {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: calc(10px + 1vmin);
  line-height: 1.25;
}

.RegularTextBold {
  font-family: "Open Sans", sans-serif;
  font-size: calc(6px + 1vmin);
  font-weight: 501;
  line-height: 1.5;
}

.RegularText {
  font-family: "Open Sans", sans-serif;
  font-size: calc(6px + 1vmin);
  line-height: 1.5;
}

.SmallText {
  font-family: "Open Sans", sans-serif;
  font-size: calc(3px + 1vmin);
  line-height: 1.5;
}

@media only screen and (max-width: 576px) {
  .RegularTextBold {
    font-family: "Open Sans", sans-serif;
    font-size: calc(6px + 3vmin);
    font-weight: 501;
    line-height: 1.5;
  }
  .SmallText {
    font-family: "Open Sans", sans-serif;
    font-size: calc(3px + 3vmin);
    line-height: 1.5;
  }
  .RegularText {
    font-family: "Open Sans", sans-serif;
    font-size: calc(6px + 3vmin);
    line-height: 1.5;
  }
}

.GreyText {
  color: darkgrey;
}

.CardGreyHeadder {
  background-color: lightsteelblue;
}

.SelectedNavText {
  border-bottom: 2px solid blue;
}

.Hide {
  display: none;
}

.Pointer {
  cursor: pointer;
}

.loginBtn {
  background-color: #4d7898;
}

.PrimaryModalFooterExtraHeight {
  height: 90px;
}

.TextLink {
  cursor: pointer;
  color: #416683;
  text-decoration: underline;
}

.TextLink:hover {
  text-decoration: none;
}

.NavBorderBottom {
  border-bottom: 0.1px solid lightgray;
  margin-bottom: 10px;
}

.MutedLinkTextColor {
  color: #416683;
}

/* User Icon */

/* .UserIcon {
  color: #4d7898;
}

.UserIcon:hover {
  color: #617fa1;
  cursor: pointer;
} */

/* .Pointer:hover {
  text-decoration: underline;
} */

/* For Troubleshooting / testing */

.BorderCheck {
  border: 5px solid red;
}

.NavCheck {
  height: 100vh;
}

/* Animation */

.animated {
  animation: slide-up 0.4s ease;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
